export default interface PartnerLoanCommissionStatus {
  id: number;
  name: string;
  description: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

const openStatus: PartnerLoanCommissionStatus = {
  id: 1,
  name: "EM_ABERTO",
  description: "Em Aberto"
};

const pendingPaymentStatus: PartnerLoanCommissionStatus = {
  id: 2,
  name: "PENDENTE_PAGAMENTO",
  description: "Pendente Pagamento"
};

const paidStatus: PartnerLoanCommissionStatus = {
  id: 3,
  name: "PAGAMENTO_EFETUADO",
  description: "Pagamento Efetuado"
};

const canceled: PartnerLoanCommissionStatus = {
  id: 4,
  name: "PAGAMENTO_CANCELADO",
  description: "Pagamento Cancelado"
};

export const PartnerLoanCommissionStatusEnum = {
  OPEN: openStatus,
  PENDING_PAYMENT: pendingPaymentStatus,
  PAID: paidStatus,
  CANCELED: canceled
};

export const partnerLoanCommissionStatusList = [
  openStatus,
  pendingPaymentStatus,
  paidStatus,
  canceled
];

