
import Vue from "vue";
import CloseBtn from "@/components/CloseBtn.vue";
import UploadFile from "@/components/UploadFile.vue";

export default Vue.extend({
  components: {
    CloseBtn,
    UploadFile
  },
  props: {
    bucket: {
      type: String,
      default: null,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    btnLabel: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: ""
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false,
      preview: null
    };
  },
  methods: {
    changed(value: any) {
      this.$emit("input", value?.[0]);
      this.preview = value?.[0];
      this.dialog = false;
      this.$notify({
        type: "success",
        text: "Enviado!"
      });
    }
  }
});
