
import { Vue, Component, Prop } from "vue-property-decorator";
import UploadDialog from "@/components/UploadDialog.vue";

import PartnerService from "@/services/partner-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
  components: {
    UploadDialog
  }
})
export default class PartnerDocumentUpload extends Vue {
  fileManagerUrl: string;

  @Prop() documentTypeId!: number;
  @Prop() partnerId!: number;
  partnerService: PartnerService;

  loading: boolean = false;

  constructor() {
    super();
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
    this.partnerService = PartnerService.getInstance();
  }

  upload(): void {
    // eslint-disable-next-line dot-notation
    const ref = this.$refs[
      "upload-partner-document-input-" + this.inputId
    ] as HTMLElement;
    ref.click();
  }

  inputChanged(e: Event): void {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    this.uploadFiles(files || undefined);
  }

  async uploadFiles(files: FileList | undefined): Promise<void> {
    this.loading = true;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const [error, partnerDocument] =
          await this.partnerService.uploadPartnerDocument(
            this.partnerId,
            this.documentTypeId,
            file
          );

        this.loading = false;

        if (error) {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(error)
          });
        } else {
          this.$emit("input", partnerDocument);
        }
      }
    }
  }
  get inputId(): string {
    return Math.random().toString(36).substring(2, 15);
  }
}
