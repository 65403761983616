
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import PartnerDocumentUpload from "@/components/partners/PartnerDocumentUpload.vue";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import dayjs from "@/plugins/day-js";
import PartnerService, {
  PartnerLoanCommissionBatchRegisterList
} from "@/services/partner-service";
import { DataTableHeader } from "vuetify";
import PartnerLoanCommissionStatus, {
  partnerLoanCommissionStatusList
} from "@/types/partner-loan-commission-status";
import PartnerLoanCommissionBatch from "@/types/partner-loan-commission-batch";

@Component({
  components: { ValidationObserver, Modal, PartnerDocumentUpload },
  directives: { mask }
})
export default class PartnerCommissionModal extends Vue {
  @Prop()
  readonly partnerLoanComissionBatch!: PartnerLoanCommissionBatch;
  formatCPF = formatCPF;
  formatCNPJ = formatCNPJ;
  batchRegisters: PartnerLoanCommissionBatchRegisterList;
  service: PartnerService;
  headers: Array<DataTableHeader>;
  statusList: PartnerLoanCommissionStatus[] = partnerLoanCommissionStatusList;
  loading = true;

  constructor() {
    super();
    this.batchRegisters = {
      data: [],
      total: 0
    };
    this.service = PartnerService.getInstance();
    this.headers = [
      { text: "Empresa", value: "commission.companyName", sortable: false },
      { text: "Parceiro", value: "commission.partner", sortable: false },
      { text: "Contrato", value: "commission.loanId", sortable: false },
      {
        text: "Data Contratação",
        value: "commission.loanContractingDate",
        sortable: false
      },
      { text: "Valor", value: "commission.loanAmount", sortable: false },
      {
        text: "Comissão",
        value: "commission.commissionPercentage",
        sortable: false
      },
      {
        text: "R$ Comissão",
        value: "commission.commissionAmount",
        sortable: false
      }
    ];
  }

  async created(): Promise<void> {
    await this.listRegisters();
    this.loading = false;
  }

  async listRegisters(): Promise<PartnerLoanCommissionBatchRegisterList> {
    const [error, batchRegisters] =
      await this.service.partnerLoanCommissionBatchRegister({
        batchId: this.partnerLoanComissionBatch.id
      });

    if (error || !batchRegisters) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de comissões"
      });
    } else {
      this.batchRegisters = batchRegisters;
    }
    return this.batchRegisters;
  }

  close(): void {
    this.$emit("close");
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  formatStatus(statusId: number): string | undefined {
    if (this.statusList?.length) {
      const statusItem = this.statusList.find((item) => item.id === statusId);

      if (statusItem) {
        return statusItem.description;
      }
    }
  }

  get totalCommissionAmount(): number {
    return this.batchRegisters.data.reduce(
      (total, item) => total + Number(item.commission.commissionAmount),
      0
    );
  }
}
