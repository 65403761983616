
import Vue from "vue";
import axios from "axios";
import FieldErrors from "@/components/FieldErrors.vue";

export default Vue.extend({
  components: {
    FieldErrors
  },
  props: {
    bucket: {
      type: String,
      default: null,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: "image/*"
    },
    droppable: {
      type: Boolean,
      default: false
    },
    maxFiles: {
      type: Number,
      default: 1
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      progressCurrent: 1,
      progressMax: 1,
      dragover: false
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_FILE_MANAGER_URL;
    },
    inputId() {
      return Math.random().toString(36).substring(2, 15);
    }
  },
  methods: {
    inputChanged(e: Event) {
      const target = e.target as HTMLInputElement;
      const files = target.files;
      if (!files) return;

      if (files.length > this.maxFiles) {
        this.$notify({
          type: "error",
          text: `Você pode enviar no máximo ${this.maxFiles} arquivos para este tipo de documento`
        });
        return;
      }

      this.uploadFiles(files || undefined);
    },
    fileDropped(e: DragEvent) {
      const files = e.dataTransfer?.files;
      if (!files) return;

      if (files.length > this.maxFiles) {
        this.$notify({
          type: "error",
          text: `Você pode enviar no máximo ${this.maxFiles} arquivos para este tipo de documento`
        });
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!file.type.match(this.accept.split(",").join("|"))) {
          this.$notify({
            type: "error",
            text: "Tipo de arquivo não permitido"
          });
          return;
        }
      }

      this.uploadFiles(files);
    },
    async uploadFiles(files: FileList | undefined) {
      this.loading = true;

      if (files && files.length) {
        this.progressCurrent = 1;
        this.progressMax = files.length;

        const responses: any[] = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const formData = new FormData();
          formData.append("file", file, file.name);
          const { data: response } = await axios.post(
            this.baseUrl + `/upload/${this.bucket}`,
            formData
          );
          responses.push(response);
          this.progressCurrent++;
        }

        this.$emit("input", responses);
        this.dragover = false;
        this.progressCurrent = 1;
        this.progressMax = 1;
      }

      this.loading = false;
    },
    upload() {
      // eslint-disable-next-line dot-notation
      const ref = this.$refs[
        "upload-image-input-" + this.inputId
      ] as HTMLElement;
      ref.click();
    }
  }
});
