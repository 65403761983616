<template>
  <div v-if="errorMessages && errorMessages.length">
    <div
      v-for="(error, errorIndex) in errorMessages"
      :key="errorIndex"
      class="error--text"
    >
      <small>{{ error }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
